import { isAdmin } from "@/contracts";
import { onConnect } from "@/wallet/wallet";
import { createRouter, createWebHashHistory } from "vue-router";
import Vesting from "../views/ClaimVesting.vue";
import wp from "../wallet";

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../views/Home.vue"),
    },
    {
      path: "/project",
      name: "Project page",
      component: () => import("../views/ManagePools.vue"),
      async beforeEnter(from, to, next) {
        const store = wp.useConnectedStore();
        if (store.connected) {
          await onConnect([import.meta.env.VITE_CHAINID]);
          if (await isAdmin()) {
            return next();
          }
          return next({ name: "Vesting" }); // moet in de else statement
        } else {
          return next({ name: "Vesting" }); // moet in de else statement
        }
      },
    },
    {
      path: "/vesting",
      name: "Vesting",
      component: Vesting,
    },
  ],
});
