<script setup lang="ts">
import { formatDate, formatTime, logoUrl } from "@/helpers";
import { notify } from "@kyvg/vue3-notification";
import { utils } from "ethers";
import Loading from "../components/base/Loading.vue";
import { addDecimals } from "@/helpers";
import { ref, onMounted, inject, reactive, computed, watch } from "vue";
import Chart, { type Dataset } from "@/components/Chart.vue";
import type { IVesting } from "../types/ethers/Vesting";
import {
  claimVestedAmount,
  getAllPools,
  getClaimableAmount,
  getReleasableAmount,
  getVestingEntry,
  hasEntry,
  isAdmin,
} from "@/contracts";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import wp from "../wallet";
import ConnectButton from "../wallet/components/ConnectButton.vue";
import DisconnectButton from "../wallet/components/DisconnectButton.vue";
import { getTokenByAddress } from "@/wallet/chain";
import { onConnect } from "@/wallet/wallet";
import { useConnectedStore } from "@/wallet/store";

const store = useConnectedStore();
const { connected, address } = storeToRefs(store);

const router = useRouter();

const currency = ref("...");
const vestingPools = reactive<Array<IVesting.VestingInfoStructOutput>>([]);
const myPools = reactive<Array<IVesting.VestingInfoStructOutput>>([]);
const activePoolId = ref<number>(0);
const myActivePoolId = ref<number>(0);
const activePool = ref<IVesting.VestingInfoStructOutput | null>(null);
const userEntries = reactive<Array<IVesting.WhitelistInfoStructOutput>>([]);
const activeEntry = ref<IVesting.WhitelistInfoStructOutput | null>(null);
const locked = ref<number>(0);
const available = ref<number>(0);
const unlocked = ref<number>(0);
const initializing = wp.useInitializing();
const admin = ref<boolean>(false);
const claimButtonText = ref<string>("Claim all tokens");

onMounted(async () => {
  setup();
});

function navigateProject() {
  router.push({
    name: "Project page",
  });
}

async function setup() {
  if (!connected.value) {
    router.push({
      name: "Home",
    });
  }
  const chainId = +import.meta.env.VITE_CHAINID;
  await onConnect([chainId]);
  const token = getTokenByAddress(import.meta.env.VITE_TOKEN_ADDRESS);
  admin.value = await isAdmin();
  currency.value = await token.symbol();
  let _vestingPools = await getAllPools();
  await initializing;
  let hasEntryPromises: Promise<boolean>[] = [];

  // Check async which pools have an entry for this wallet
  hasEntryPromises = _vestingPools.map((_, index) => {
    return hasEntry(index, address.value);
  });
  let hasEntryResult = await Promise.all(hasEntryPromises);

  // Get index of pools where user has entry.
  // Check for true values in boolean array
  let hasEntryResultIndexes = [...hasEntryResult.keys()].filter(
    (i) => hasEntryResult[i],
  );
  // Set
  let _myPools = _vestingPools.filter((_, index) =>
    hasEntryResultIndexes.includes(index),
  );

  Object.assign(myPools, _myPools);
  Object.assign(vestingPools, _vestingPools);

  if (connected.value) {
    await fetchUserEntries();
  }

  if (myPools.length == 0) {
    router.push({
      name: "NotFound",
    });
  } else {
    setActivePool(0);
  }
}

watch(connected, (isConnected: boolean) => {
  if (!isConnected) {
    activePool.value = null;
    activeEntry.value = null;
    router.push({
      name: "Home",
    });
  } else {
    setup();
  }
});

async function fetchUserEntries() {}

const claimTokens = async () => {
  let result = await claimVestedAmount(activePoolId.value, address.value);
};
</script>

<template>
  <div class="wrapper mauto rel auto-h">
    <div class="logo abs">
      <img src="../assets/logo.png" />
    </div>
    <div class="manage abs round centerflex">
      <DisconnectButton />
      <ConnectButton />
    </div>
    <div class="presales">
      <h1 class="titleh1">Projects</h1>
      <div class="presale-container">
        <div class="presale">
          <div class="banner">
            <div class="logo"><img :src="logoUrl" /></div>
            <div class="status">Upcoming</div>
          </div>
          <div class="content">
            <div class="title">Get Kicks</div>
            <div class="ratio">1 KICK = 0.5 USDT</div>

            <progress
              class="progress progress-success w-56 progress-bar"
              value="10"
              max="100"
            ></progress>
            <div style="clear: both"></div>
            <div class="title" style="font-size: 14px; margin-top: 30px">
              Sale starts in:
            </div>
            <div
              class="flex gap-5"
              style="width: 60%; height: 40px; float: left; margin-left: 20px"
            >
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 15"></span>
                </span>
                days
              </div>
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 10"></span>
                </span>
                hours
              </div>
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 24"></span>
                </span>
                min
              </div>
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 53"></span>
                </span>
                sec
              </div>
            </div>
          </div>
          <button class="btn btn-success presale-button">View</button>
        </div>
        <div class="presale">
          <div class="banner">
            <div class="logo" style="margin-top: -10px">
              <img src="../assets/bithotel/logo.png" />
            </div>
            <div class="status live">Live</div>
          </div>
          <div class="content">
            <div class="title">Bit Hotel</div>
            <div class="ratio">1 BTH = 0.25 USDT</div>

            <progress
              class="progress progress-success w-56 progress-bar"
              value="50"
              max="100"
            ></progress>
            <div style="clear: both"></div>
            <div style="clear: both"></div>
            <div class="title" style="font-size: 14px; margin-top: 30px">
              Progress
            </div>
            <div class="progress-sale">50%</div>
          </div>
          <button
            class="btn btn-success presale-button"
            @click="navigateProject"
          >
            View
          </button>
        </div>
        <div class="presale">
          <div class="banner">
            <div class="logo" style="width: 100px">
              <img src="../assets/alterverse-logo.png" />
            </div>
            <div class="status">Upcoming</div>
          </div>
          <div class="content">
            <div class="title">Alterverse</div>
            <div class="ratio">1 ALT = 0.05 USDT</div>

            <progress
              class="progress progress-success w-56 progress-bar"
              value="10"
              max="100"
            ></progress>
            <div style="clear: both"></div>
            <div class="title" style="font-size: 14px; margin-top: 30px">
              Sale starts in:
            </div>
            <div
              class="flex gap-5"
              style="width: 60%; height: 40px; float: left; margin-left: 20px"
            >
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 15"></span>
                </span>
                days
              </div>
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 10"></span>
                </span>
                hours
              </div>
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 24"></span>
                </span>
                min
              </div>
              <div>
                <span class="countdown font-mono text-4xl">
                  <span style="--value: 53"></span>
                </span>
                sec
              </div>
            </div>
          </div>
          <button class="btn btn-success presale-button">View</button>
        </div>
      </div>

      <div class="hero" style="height: 550px">
        <div class="hero-content flex-col lg:flex-row">
          <img
            src="../assets/crypto-gaming.jpeg"
            class="max-w-sm rounded-lg shadow-2xl"
          />
          <div style="margin-left: 20px">
            <h1 class="text-5xl font-bold">
              Empowering the Future of Crypto Projects!
            </h1>
            <p class="py-6">
              Not every project makes it to SkaleStarter. Our team of experts
              carefully evaluates and selects projects based on innovation,
              viability, and long-term potential. This commitment to quality
              ensures that our community has access to the most promising
              opportunities.
            </p>
            <button class="btn btn-primary">Contact</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
.progress-sale {
  font-size: 42px;
  font-weight: bold;
  float: left;
  margin-top: -20px;
  margin-left: 20px;
}
.live {
  color: #10b981 !important;
  background-color: #d1fae5 !important;
}
.presale-button {
  float: left;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.wrapper {
  font-family: "Open Sans";
  font-style: normal;
}

.wrapper {
  width: 100%;
  height: auto;
  float: left;
  padding-bottom: 150px;
  overflow-x: hidden !important;
  overflow-y: hidden;
  color: var(--color-text-primary);
  background: var(--color-dark-manage-vesting);

  .title {
    text-align: left;
  }

  .logo {
    width: 100px;
    height: 100px;
    top: 20px;
    left: 20px;

    img {
      width: 100%;
    }
  }

  .manage {
    background: var(--color-dark-manage-vesting);
    top: 20px;
    right: 20px;
    color: var(--color-text-primary);

    .add-currency {
      height: 50px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: 1px transparent solid;
      cursor: pointer;

      &:hover {
        border: 1px #367de7 solid;
        color: #367de7;
      }
    }
  }

  .progress-bar {
    float: left;
    margin-left: 20px;
    height: 20px;
    margin-top: 10px;
  }
  .connect {
    height: 100vh;
    width: 100%;

    .wallet {
      top: 0;
      right: 0;
    }
  }

  .presales {
    width: 100%;
    height: auto;
    float: left;
    background: var(--color-dark-manage-vesting);

    .titleh1 {
      font-size: 42px;
      font-weight: bold;
      margin-top: 200px;
    }

    .presale-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
      width: 80%;
      margin-left: 18%;
      margin-top: 30px;
    }

    .presale {
      height: 420px;
      border: 1px solid #999;
      border-radius: 20px;
      background: #333342;
      cursor: pointer;
      position: relative;

      .banner {
        width: 100%;
        height: 100px;
        float: left;

        .logo {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          // background: green;
          float: left;
          margin-top: 20px;
          margin-left: 20px;
        }

        .status {
          width: 100px;
          border-radius: 25px;
          height: 30px;
          float: right;
          background: #ffe799;
          color: #d29813;
          text-align: center;
          line-height: 30px;
          font-size: 12px;
          margin-top: 15px;
          margin-right: 10px;
        }
      }
      .title {
        font-size: 28px;
        font-weight: bold;
        margin-left: 20px;
        margin-top: 0px;
        display: block;
      }
      .content {
        width: 100%;
        height: auto;
        float: left;

        .ratio {
          font-size: 12px;
          color: #999;
          text-align: left;
          margin-left: 20px;
          margin-top: -12px;
        }
      }
    }
  }
  .holder {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 80px;
    position: relative;
    z-index: 1;
  }

  .sides {
    margin-top: 200px;
    width: 90%;
    margin: 0 auto;

    .menu {
      margin: auto;
      min-height: 80px;
      align-items: center;
      width: 40%;
      display: flex;
      justify-content: space-around;

      .item,
      .router-link {
        z-index: 2;
        color: #565c6b;
        height: 30px;
        width: auto;
        text-decoration: none;
        cursor: pointer;
      }

      .active {
        color: #fff;
      }
    }

    .container {
      display: grid;
      width: 100%;
      grid-template-columns: 4fr 10fr;
      grid-template-rows: 240px 356px 20px;
      gap: 20px;

      .box {
        padding: 25px;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(15px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;

        .title {
          text-align: left;
          margin-bottom: 5px;
        }

        .subtitle {
          text-align: left;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          /* identical to box height */

          color: #636363;
        }

        .date-wrap {
          width: 100%;
          display: flex;
          justify-content: space-between;
          grid-template-columns: 1fr 1fr;
          margin: 10px 0;
        }

        .name {
          font-weight: 800;
          font-size: 15px;
          line-height: 152.4%;

          letter-spacing: 0.035em;

          color: #ffffff;
        }

        .date-time {
          text-align: right;
          color: #f40319;
        }

        .date {
          color: #f40319;
          font-weight: 400;
          font-size: 16px;
          text-align: right;
          letter-spacing: 0.035em;
          margin-bottom: 4px;
        }

        .time {
          float: right;
          color: #636363;
          font-weight: 100;
          font-size: 14px;
        }

        .claim {
          width: 80%;
        }
      }

      .box1 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      .box2 {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        z-index: 1; // bigger than ellipse in claimvesting

        .date-wrap {
          margin: 25px 0;
        }
      }

      .box3 {
        grid-column: 2 / 2;
        grid-row: 1 / 4;
      }
    }

    .ellipse {
      z-index: 0;
    }

    .ellipse1 {
      width: 1274px;
      height: 1274px;
      left: -440px;
      top: 679px;

      background: #bd3636;
      opacity: 0.15;
      filter: blur(350px);
    }

    .ellipse2 {
      width: 818px;
      height: 818px;
      left: 614px;
      top: 56px;

      background: #9a36bd;
      opacity: 0.1;
      filter: blur(350px);
    }

    .ellipse3 {
      width: 2760px;
      height: 2760px;
      left: 42px;
      top: 765px;

      background: #b236bd;
      opacity: 0.15;
      filter: blur(350px);
    }
  }
}

@media only screen and (max-width: 1280px) {
  .wrapper .sides .container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 350px 480px 20px;
  }

  .wrapper .sides .container .box2 {
    grid-column: 2/2;
    grid-row: 1/2;
  }

  .wrapper .sides .container .box3 {
    grid-column: 3/1;
    grid-row: 2/4;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper .sides .menu {
    margin-top: 80px;
  }

  .wrapper .holder {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100vw;
  }

  .sides {
    width: 90%;
  }

  .wrapper .sides .container {
    grid-template-columns: 1fr;
    grid-template-rows: 350px 350px 550px;
    gap: 20px 0;
  }

  .wrapper .sides .container .box2 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .wrapper .sides .container .box1 {
    grid-column: 1/2;
    grid-row: 2/2;
  }

  .wrapper .sides .container .box3 {
    grid-column: 3/1;
    grid-row: 3/4;
  }
}

@media only screen and (max-width: 700px) {
  // .sides {
  //   min-width: 90px !important;
  //   width: 100%;
  // }
  .sides {
    width: 90%;

    .container {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 240px 356px 516px;
      gap: 20px;

      .box1 {
        grid-column: 1 / 1;
        grid-row: 1 / 2;
      }

      .box2 {
        grid-column: 1 / 1;
        grid-row: 2 / 3;

        .date-wrap {
          margin: 25px 0;
        }
      }

      .box3 {
        grid-column: 1 / 1;
        grid-row: 3 / 4;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .sides {
    width: 100%;
  }
}
</style>
